import React, { Suspense } from 'react';
import Users from 'pages/users';
import Reports from 'pages/reports';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useAuth } from 'services/auth';
import Landing from 'pages/landing';
import Loading from 'components/Loading';
import NotFound from 'pages/not-found';
import Feedback from 'pages/feedback';
import Books from 'pages/books';

export interface IRouteProps {
  component: React.LazyExoticComponent<React.ComponentType<any>> | React.ComponentType<any>;
  path: string;
  isPrivate?: boolean;
  exact?: boolean;
}

export const RenderChildren = (routes: IRouteProps[], parentPath: string) => {
  return routes.map((route: IRouteProps) => {
    const { isPrivate, path, component } = route;
    const newPath = `${parentPath}/${path}`;
    if (isPrivate) {
      return <PrivateRoute path={newPath} component={component} key={newPath} />;
    }
    return <Route path={newPath} component={component} key={newPath} />;
  });
};

const PrivateRoute = (props: IRouteProps) => {
  const { isAuth } = useAuth();

  if (!isAuth) {
    return <Redirect to={Landing.path} />;
  }
  return <Route {...props} />;
};

const NonPrivateRoute = (props: IRouteProps) => {
  const { isAuth } = useAuth();

  if (isAuth) {
    return <Redirect to={Reports.path} />;
  }
  return <Route {...props} />;
};

function Pages() {
  const location = useLocation();

  return (
    <Suspense fallback={<Loading />}>
      <Switch location={location}>
        <NonPrivateRoute component={Landing.component} path={Landing.path} exact />

        <PrivateRoute component={Users.component} path={Users.path} />

        <PrivateRoute component={Feedback.component} path={Feedback.path} />

        <PrivateRoute component={Books.component} path={Books.path} />

        <PrivateRoute component={Reports.component} path={Reports.path} />

        <Route path="*" exact component={NotFound} />
      </Switch>
    </Suspense>
  );
}

export default Pages;
