import Books from 'pages/books';
import Feedback from 'pages/feedback';
import Reports from 'pages/reports';
import Users from 'pages/users';
import { Link, useLocation } from 'react-router-dom';
import { NAVIGATION } from 'utils/texts';

function Navigation() {
  const location = useLocation();

  const isActive = (currentPath: string) => currentPath === location.pathname;

  return (
    <ul className="menu items-stretch px-3 shadow-lg bg-base-100 horizontal rounded-box">
      <li className={isActive(Reports.path) ? 'bordered' : ''}>
        <Link to={Reports.path}>{NAVIGATION.reports}</Link>
      </li>
      <li className={isActive(Users.path) ? 'bordered' : ''}>
        <Link to={Users.path}>{NAVIGATION.users}</Link>
      </li>
      <li className={isActive(Feedback.path) ? 'bordered' : ''}>
        <Link to={Feedback.path}>{NAVIGATION.feedback}</Link>
      </li>
      <li className={isActive(Books.path) ? 'bordered' : ''}>
        <Link to={Books.path}>{NAVIGATION.books}</Link>
      </li>
    </ul>
  );
}

export default Navigation;
