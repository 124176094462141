import React, { useEffect, useState } from 'react';

interface IImageProps {
  path: string;
  alt?: string;
  fallback?: string;
  asBackground?: boolean;
}

function Image({ path, alt, asBackground, fallback }: IImageProps) {
  const [imagePath, setImagePath] = useState(path || fallback);

  useEffect(() => {
    if (path) {
      setImagePath(path);
    }
  }, [path]);

  return (
    <>
      {asBackground && (
        <div style={{ backgroundImage: `url(${path})` }} className="h-full bg-center bg-cover bg-no-repeat" />
      )}
      {!asBackground && (
        <img
          src={imagePath}
          onError={() => {
            if (fallback) {
              setImagePath(fallback);
            }
          }}
          alt={alt}
          className="w-full object-cover"
        />
      )}
    </>
  );
}

export default Image;
