import React from 'react';
import { ProvideAuth } from 'services/auth';
import { BrowserRouter } from 'react-router-dom';
import Pages from 'pages';
import Layout from 'components/Layout';

function App() {
  return (
    <ProvideAuth>
      <BrowserRouter>
        <Layout>
          <Pages />
        </Layout>
      </BrowserRouter>
    </ProvideAuth>
  );
}

export default App;
