import Header from 'components/Header';

function Layout({ children }: any) {
  return (
    <div className="container mx-auto px-4">
      <Header />
      {children}
    </div>
  );
}

export default Layout;
