import { useCheckLoginLazyQuery, useCheckLoginQuery, useLoginMutation, useLogoutMutation, User } from 'generated';
import React, { useState, useContext, createContext, useEffect } from 'react';

interface IAuthProvider {
  user: User | null;
  isAuth: boolean;
  loading: boolean;
  signin(email: string, password: string): Promise<any>;
  signout(): Promise<any>;
}

const AuthContext = createContext({} as IAuthProvider);

export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

const useProvideAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [login, { loading: loginLoading }] = useLoginMutation();
  const [logout] = useLogoutMutation();

  const { data, loading: checkLoginLoading } = useCheckLoginQuery();

  useEffect(() => {
    if (data?.checkLogin && !checkLoginLoading) {
      setUser(data.checkLogin);
    }
  }, [data, checkLoginLoading]);

  const signin = async (email: string, password: string) => {
    setLoading(true);
    return login({
      variables: {
        loginDto: {
          email,
          password
        }
      }
    })
      .then((response) => {
        if (response.data?.adminLogin?.user) {
          setUser(response.data?.adminLogin.user);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const signout = () => {
    setLoading(true);
    return logout()
      .then((response) => {
        if (response.data?.adminLogout) {
          setUser(null);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return {
    user,
    loading: loading || loginLoading || checkLoginLoading,
    isAuth: !loading && !!user,
    signin,
    signout
  };
};

export const useAuth = () => {
  return useContext(AuthContext);
};
