import Landing from 'pages/landing';
import { Link } from 'react-router-dom';
import { NOT_FOUND_PAGE } from 'utils/texts';

function NotFound() {
  return (
    <div className="text-center h-full flex flex-col justify-center align-center">
      <div>
        <p className="text-gray-500 text-2xl">{NOT_FOUND_PAGE.title}</p>
        <Link to={Landing.path}>{NOT_FOUND_PAGE.redirect}</Link>
      </div>
    </div>
  );
}

export default NotFound;
