export const NOT_FOUND_PAGE = {
  title: 'Not found',
  redirect: 'Go back to main page'
};

export const NO_DATA = {
  title: 'No content to display!'
};

export const NAVIGATION = {
  reports: 'Reports',
  users: 'Users',
  feedback: 'Feedback',
  books: 'Books',
  logout: 'Logout'
};

export const FORM = {
  email: 'Email',
  password: 'Password',
  login: 'Login',
  loadMore: 'Load more',
  firstName: 'First name',
  lastName: 'Last name',
  username: 'Username',
  isAdmin: 'Is Admin'
};

export const STATUS = {
  title: 'Status',
  DRAFT: 'Draft',
  EDIT: 'Edit',
  PUBLISHED: 'Published'
};

export const USERS = {
  view: 'View user info',
  createdAt: 'Created at',
  updatedAt: 'Updated at',
  isBanned: 'Is banned',
  isDeleted: 'Is deleted',
  bio: 'Bio',
  ban: 'Ban user',
  update: 'Update user',
  username: 'Username',
  unban: 'Remove ban',
  banMotive: 'Motive',
  facebook: 'Facebook'
};

export const REPORTS = {
  reporter: 'Reporter',
  origin: 'Origin',
  originId: 'Origin ID',
  authorContent: 'Author content'
};

export const BOOKS = {
  status: 'Status',
  mature: 'Is mature',
  premium: 'Is premium',
  stats: 'Stats',
  likes: 'Likes',
  comments: 'Comments',
  fragments: 'Fragments',
  alternativeVersions: 'Forks',
  reads: 'Reads'
};
