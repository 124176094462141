import Button from 'components/Form/Button';
import { useAuth } from 'services/auth';
import { NAVIGATION } from 'utils/texts';

function Logout() {
  const { signout } = useAuth();

  const onSubmit = () => {
    return signout();
  };

  return (
    <Button small onClick={onSubmit}>
      {NAVIGATION.logout}
    </Button>
  );
}

export default Logout;
