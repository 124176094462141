import Image from 'components/Image';
import Navigation from 'components/Header/navigation';
import { useAuth } from 'services/auth';
import Logout from 'components/Header/logout';

function Header() {
  const { isAuth } = useAuth();

  return (
    <div className="flex flex-col justify-center items-center py-5 space-y-10">
      <div className="w-31">
        <Image path="/logo.svg" />
      </div>
      {isAuth && (
        <div className="flex space-x-4 items-center">
          <Navigation />
          <Logout />
        </div>
      )}
    </div>
  );
}

export default Header;
