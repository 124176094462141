import Image from 'components/Image';

function Loading() {
  return (
    <div className="flex items-center relative-height justify-center">
      <div className="w-24">
        <Image path="/loader.svg" />
      </div>
    </div>
  );
}

export default Loading;
