import cx from 'classnames';
import React from 'react';

interface IButtonProps {
  children?: string | React.ReactElement;
  icon?: React.ReactElement;
  hidden?: boolean;
  outline?: boolean;
  disabled?: boolean;
  small?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit';
  onClick?(e: any): void;
}

function Button({
  children,
  icon,
  type,
  onClick,
  disabled = false,
  loading = false,
  hidden = false,
  small = false,
  outline = false
}: IButtonProps) {
  return (
    <button
      className={cx(
        {
          hidden,
          loading,
          'pointer-events-none opacity-60': disabled,
          'btn-outline': outline,
          'h-10 min-h-0': small
        },
        'btn btn-primary rounded-full px-5 space-x-2'
      )}
      type={type || 'button'}
      onClick={disabled ? undefined : onClick}
    >
      {icon &&
        !loading &&
        React.cloneElement(icon, {
          className: cx(icon.props.className || 'w-4 h-4', {
            'mr-2': !!children
          })
        })}
      {!loading && children}
    </button>
  );
}

export default Button;
